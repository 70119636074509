import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import qs from 'qs';
import { useRouter } from 'next/router';
import './../node_modules/@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import * as Sentry from '@sentry/nextjs';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import {
  Layout,
  Body,
  Card,
  Heading,
  FormDescription,
  Text,
  PageHead,
} from '@/components/Index';
import useUrlQuery from '@/components/hooks/useUrlQuery';

const ShowError = dynamic(() => import('@/components/ShowError'));
const ShowAlert = dynamic(() => import('@/components/ShowAlert'));
const LoginSuccess = dynamic(() => import('@/components/LoginSuccess'));
const Loading = dynamic(() => import('@/components/Loading'));

import { login } from '@/services/index';
import { filterOriginUrl } from '@/libs/index';

import { convertErrorCode, getAuthClient, getIdpLoginHref } from '@/libs/okta';
import useHasIdToken from '@/components/hooks/useHasIdToken';
import {
  getFromStorage,
  removeFromStorage,
  setToStorage,
} from '@/libs/storage';
import { removeCookie } from '@/libs/cookie';
const ORIGINAL_URL_KEY = '__vungle_original_url';
const PREFILLED_EMAIL_KEY = '__vungle_prefilled_email';
const IMPERSONATED_EMAIL_KEY = '__vungle_impersonated_email';
const ORIGINAL_URL_AFTER_LOGOUT_KEY = '__vungle_original_after_logout_url';
const ORIGINAL_AUTH_METHOD = 'auth_method';

const idpLoginHref = getIdpLoginHref();
const authClient = getAuthClient();

function getEmailAlert(email) {
  return (
    <p>
      The email <span className="font-bold">{email}</span> already has an
      account. Please use existing credentials to Log In.
    </p>
  );
}

// why are we storing a key:value pair? because local-storage library json stringifies the email and
// that breaks emails like qamar2ham+100@gmail.com that have special characters.
function getImpersonatedEmail() {
  let impersonatedEmail;
  if (typeof window !== 'undefined') {
    impersonatedEmail = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).asuserEmail;
  }
  return { impersonatedEmail };
}

function getOriginal() {
  if (typeof window !== 'undefined') {
    return qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    }).original;
  }
}

const ctrlDomains = [
  /(pub|adv)-ctrl-.+\.vungle\.io/,
  /(pubadmin|ctrl)\.vungle\.com/,
];
function verifyCallback(isInternal, originalUrl) {
  if (!isInternal) {
    for (const domain of ctrlDomains) {
      if (domain.test(originalUrl)) {
        return null;
      }
    }
  }

  return originalUrl;
}

function checkIsSSOLoginError(err) {
  return (
    Array.isArray(err?.messages) &&
    err?.messages.length > 0 &&
    err?.messages[0].indexOf('login with SSO') > -1
  );
}

export default function Login() {
  const router = useRouter();
  const original = router.query.original;
  const emailFromUrl = useUrlQuery('email');
  const { impersonatedEmail } = getImpersonatedEmail();
  const [prefilledEmail, setPrefilledEmail] = useState('');
  const [responseError, setResponseError] = useState(false);
  const [isSSOLoginError, setIsSSOLoginError] = useState(false);
  const [loginSuccessResults, setLoginSuccessResults] = useState(null);
  const hasIdToken = useHasIdToken();
  const originalUrl = filterOriginUrl(getOriginal());

  const loginFormSchema = yup.object().shape({
    email: yup.string().email().required('Email is required.'),
    password: yup.string().required('Password is required.'),
  });

  const { setValue, getValues } = useForm({
    resolver: yupResolver(loginFormSchema),
  });

  const OktaSignInWidget = dynamic(
    () =>
      import('@/components/OktaSignInWidget').then(
        (mod) => mod.OktaSignInWidget
      ),
    { ssr: false }
  );

  const handleSuccess = ({ data }, originalUrl) => {
    removeFromStorage(ORIGINAL_URL_KEY);
    removeFromStorage(PREFILLED_EMAIL_KEY);
    removeFromStorage(IMPERSONATED_EMAIL_KEY);
    sessionStorage.removeItem(ORIGINAL_AUTH_METHOD);
    const callback = verifyCallback(data.isInternal, originalUrl);
    const dataWithOriginalUrl = callback
      ? { ...data, originalUrl: callback }
      : data;
    setLoginSuccessResults(dataWithOriginalUrl);
    setResponseError(false);
  };

  const handleFailure = async (err) => {
    const isLoginSSOError = checkIsSSOLoginError(err);
    // login error means all errors returned from login endpoint except the unknown errors, and the errors are caught by our auth service,
    const isLoginError = Array.isArray(err.messages) && err.messages.length > 0;

    setTimeout(async () => {
      // we need to sign out the token when the errors caught by login endpoint of auth service, cuz it is not a valid token even it is auth by okta already
      if (isLoginSSOError || isLoginError) {
        removeCookie('username');
        removeCookie('token');

        const originalUrl = original || '/';
        setToStorage(ORIGINAL_URL_AFTER_LOGOUT_KEY, originalUrl);

        await authClient.signOut({
          postLogoutRedirectUri:
            process.env.NEXT_PUBLIC_OKTA_LOGOUT_REDIRECT_URL,
          revokeAccessToken: false,
        });
      }
    }, 3000);

    setIsSSOLoginError(isLoginSSOError);
    setResponseError(err);
  };

  // this runs on page refresh or after Okta redirect
  useEffect(() => {
    const afterOktaLogin = async (email) => {
      const originalUrl = getFromStorage(ORIGINAL_URL_KEY);
      try {
        const tokens = await authClient.tokenManager.getTokens();

        if (!tokens.accessToken.accessToken) {
          Sentry.captureException({
            message: `null access token for email ${email}`,
            tokens,
            idToken: tokens.idToken.idToken,
          });
        }
        const impersonatedEmail = getFromStorage(IMPERSONATED_EMAIL_KEY);
        const res = await login({
          email: impersonatedEmail ? `${email}::${impersonatedEmail}` : email,
          token: tokens.accessToken.accessToken,
          method: sessionStorage.getItem(ORIGINAL_AUTH_METHOD),
        });
        handleSuccess(res, originalUrl);
      } catch (e) {
        handleFailure(e);
      }
    };

    async function load() {
      // we always try to login with okta if okta first, because if okta session cookie exists, we can just pull the user
      // because the user is already SSO'ed
      // if this is a redirect after signInWithRedirect
      if (authClient.isLoginRedirect()) {
        // this only happens after okta's own redirect (SSO one). it means only after signInWithRedirect
        try {
          // this means we have accessToken in url fragment
          const res = await authClient.token.parseFromUrl();
          authClient.tokenManager.setTokens(res.tokens);
          // this means if it accessToken exists in OUR domain, not okta's
          // if not authenticated, the user is presented with the login page
          const isAuthenticated = await authClient.isAuthenticated();
          if (isAuthenticated) {
            const user = await authClient.token.getUserInfo();
            await afterOktaLogin(user.email);
          }
        } catch (e) {
          const prefilledEmail = getFromStorage(PREFILLED_EMAIL_KEY);
          if (prefilledEmail) {
            setPrefilledEmail(prefilledEmail);
          }
          const error = convertErrorCode(e.errorCode);
          if (error.code !== 401) {
            handleFailure(error);
          }
        }
      } else {
        // try to login with okta SSO (okta's session cookie)
        await authClient.signInWithRedirect({
          prompt: 'none',
          originalUri: window.location.href,
          nonce: process.env.NEXT_PUBLIC_OKTA_NONCE,
        });
      }
    }

    if (emailFromUrl) {
      setPrefilledEmail(emailFromUrl);
      setToStorage(PREFILLED_EMAIL_KEY, emailFromUrl);
    }
    if (impersonatedEmail) {
      setToStorage(IMPERSONATED_EMAIL_KEY, impersonatedEmail);
    }
    load();
    return () => {
      setLoginSuccessResults(null);
    };
  }, [emailFromUrl, impersonatedEmail]);

  useEffect(() => {
    // after okta redirect and token extraction, the `original` param is removed from URL, so we need to store it in local storage
    if (originalUrl) {
      setToStorage(ORIGINAL_URL_KEY, originalUrl);
    }
  }, [originalUrl]);

  useEffect(() => {
    const emailValue = getValues('email');
    if (!emailValue) {
      setValue('email', prefilledEmail);
    }
  });

  return (
    <>
      <PageHead title="Login" description="Liftoff login page." />
      <Layout>
        <Body>
          {!loginSuccessResults && !hasIdToken && (
            <Card dataTestId="user-login">
              <Heading text="Oh hello there!" />
              <FormDescription content="Log in to Liftoff Direct/Liftoff Monetize." />
              <OktaSignInWidget />
              {responseError && (
                <div className="mt-16">
                  <ShowError err={responseError} />
                </div>
              )}
              {prefilledEmail && (
                <div className="mt-8">
                  <ShowAlert>{getEmailAlert(prefilledEmail)}</ShowAlert>
                </div>
              )}
              <div className="mt-32">
                <Text>
                  Don&apos;t have an account yet?
                  <Link href="/signup">
                    <a data-testid="signup-link"> Sign up</a>
                  </Link>
                </Text>
                <div className="mt-64">
                  <a href={idpLoginHref}>Employee Login</a>
                </div>
              </div>
            </Card>
          )}
          {isSSOLoginError && (
            <div className="absolute inset-0 w-full h-full "></div>
          )}
          {!loginSuccessResults && hasIdToken && <Loading />}
          {loginSuccessResults && (
            <LoginSuccess results={loginSuccessResults} />
          )}
        </Body>
      </Layout>
    </>
  );
}
