import { request } from '@/libs/index';

function forgotPasswordRequest({ email } = { email: '' }) {
  return request({
    method: 'POST',
    url: '/forgot',
    data: {
      username: email,
    },
  }).catch((e) => Promise.reject(e));
}

export { forgotPasswordRequest as default };
