import { request } from '@/libs/index';

function loginRequest({ email = '', password = '', token = '', method = '' }) {
  return request({
    method: 'POST',
    url: '/login',
    headers: {
      'vungle-source': 'auth',
    },
    data: {
      username: email,
      password,
      token,
      method: method ? method : 'username_password',
    },
  }).catch((e) => Promise.reject(e));
}

export { loginRequest as default };
