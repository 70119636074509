import React from 'react';
import Image from 'next/image';
import { Text } from '@/components/Index';

function Loading() {
  return (
    <>
      <div className="flex h-full pt-[120px] justify-center items-center">
        <div className="block">
          <Text>Preparing for liftoff...</Text>
          <div className="flex mt-32 justify-center">
            <Image
              src="/images/loading.svg"
              alt="Loading..."
              width={20}
              height={20}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Loading;
