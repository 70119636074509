import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';

function useUrlQuery(name) {
  const [queryValue, setQueryValue] = useState(undefined);

  const router = useRouter();
  const tempValue =
    name && typeof name === 'string' && name.trim().length > 1
      ? router?.query[name]
      : undefined;

  useEffect(() => {
    if (tempValue) {
      const decodedValue = decodeURIComponent(tempValue);
      if (decodedValue) {
        setQueryValue(decodedValue);
      }
    }
  }, [tempValue]);

  return queryValue;
}

export default useUrlQuery;
