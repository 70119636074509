import { request } from '@/libs/index';

async function getOktaUserRequest(payload) {
  const { data: user } = await request({
    method: 'GET',
    url: `/okta-user/${payload.email}`,
  });
  // auth api always returns an object due to the limitations of in-house "resource" framework. if email is null it means that no user was found
  if (user.email) {
    user.organization = user.company || `${user.firstName} ${user.lastName}`;
    user.country = user.country || 'US';
    user.account_type = 'both';
    return user;
  } else {
    return undefined;
  }
}

export { getOktaUserRequest as default };
