import { request } from '@/libs/index';

function loginSSORequest({ email } = { email: '' }) {
  return request({
    method: 'GET',
    url: '/login/allowedSSO',
    params: {
      username: email,
    },
  }).catch((e) => Promise.reject(e));
}

export { loginSSORequest as default };
