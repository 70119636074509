import { request } from '@/libs/index';

export function newSignupRequest({
  existsInOkta = false,
  email = '',
  firstName = '',
  lastName = '',
  account_name = '',
  password = '',
  country = 'US',
  account_type = 'both',
  jobPosition = '',
  token = '',
  application_url = '',
  bundle_id = '',
  dev_email = '',
  skype = '',
  wechat = '',
  qq_num = '',
  account_website = '',
  is_pub = false,
}) {
  return request({
    method: 'POST',
    url: '/signup',
    data: {
      existsInOkta,
      username: email,
      firstName,
      lastName,
      account_name,
      password: password,
      country,
      account_type, // 'publisher', 'advertiser' or 'both'
      jobPosition,
      token,
      application_url,
      bundle_id,
      dev_email,
      skype,
      wechat,
      qq_num,
      account_website,
      is_pub,
    },
  });
}

function signupRequest({
  existsInOkta = false,
  email = '',
  firstName = '',
  lastName = '',
  organization = '',
  password = '',
  country = 'US',
  account_type = 'both',
  jobPosition = '',
  token = '',
  is_pub = false,
}) {
  return request({
    method: 'POST',
    url: '/register',
    data: {
      existsInOkta,
      username: email,
      firstName,
      lastName,
      account_name: organization,
      password: password,
      country: country,
      account_type, // 'publisher', 'advertiser' or 'both'
      jobPosition,
      token,
      is_pub,
    },
  });
}

export { signupRequest as default };
