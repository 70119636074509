import { OktaAuth } from '@okta/okta-auth-js';
import qs from 'qs';

const OKTA_SCOPES = ['openid', 'email'];
const OKTA_RESPONSE_MODE = 'fragment';
const OKTA_RESPONSE_TYPE = ['token', 'id_token'];

function getAuthClient() {
  const config = {
    issuer: process.env.NEXT_PUBLIC_OKTA_ISSUER,
    clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
    redirectUri: process.env.NEXT_PUBLIC_OKTA_LOGIN_REDIRECT_URL,
    responseType: OKTA_RESPONSE_TYPE,
    responseMode: OKTA_RESPONSE_MODE,
    pkce: false,
    state: process.env.NEXT_PUBLIC_OKTA_STATE,
    scopes: OKTA_SCOPES,
  };
  return new OktaAuth(config);
}

function getErrorResponse(message, code) {
  return {
    code,
    messages: [message],
  };
}

function convertErrorCode(oktaErrorCode) {
  // TODO handle more error codes here
  switch (oktaErrorCode) {
    case 'E0000004':
      return getErrorResponse(
        'Invalid username and password combination.',
        403002
      );
    case 'access_denied':
      return getErrorResponse(
        'Access denied. This is likely due to a missing OKTA group assignment. Please contact IT Service Desk to update the group assignment.',
        403003
      );
    default:
      return getErrorResponse('Authentication failed', 401);
  }
}

async function getOktaSessionToken(authClient, username, password) {
  try {
    const transaction = await authClient.signInWithCredentials({
      username,
      password,
    });

    if (transaction.status === 'SUCCESS') {
      const tokenRes = await authClient.token.getWithRedirect({
        sessionToken: transaction.sessionToken,
        responseType: OKTA_RESPONSE_TYPE,
        responseMode: OKTA_RESPONSE_MODE,
        pkce: false,
        state: process.env.NEXT_PUBLIC_OKTA_STATE,
        nonce: process.env.NEXT_PUBLIC_OKTA_NONCE,
        scopes: OKTA_SCOPES,
      });
      return tokenRes;
    } else {
      throw convertErrorCode('000000');
    }
  } catch (ex) {
    throw convertErrorCode(ex.errorCode);
  }
}

function getIdpLoginHref() {
  const params = {
    idp: process.env.NEXT_PUBLIC_OKTA_IDP,
    client_id: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
    state: process.env.NEXT_PUBLIC_OKTA_STATE,
    nonce: process.env.NEXT_PUBLIC_OKTA_NONCE,
    scope: OKTA_SCOPES.join(' '),
    response_mode: OKTA_RESPONSE_MODE,
    response_type: OKTA_RESPONSE_TYPE.join(' '),
    redirect_uri: process.env.NEXT_PUBLIC_OKTA_LOGIN_REDIRECT_URL,
  };
  const q = qs.stringify(params);

  return `${process.env.NEXT_PUBLIC_OKTA_ISSUER}/v1/authorize?${q}`;
}

function getExternalIDPLoginHrefByType(type) {
  const tempType = (type || '').trim().toLowerCase()
  if (tempType === 'microsoft') {
    return getExternalIDPMicrosoftLoginHref()
  }
  throw `${type} is not supported yet.`
}

function getExternalIDPMicrosoftLoginHref() {
  const params = {
    idp: process.env.NEXT_PUBLIC_MICROSOFT_IDP,
    client_id: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
    state: process.env.NEXT_PUBLIC_OKTA_STATE,
    nonce: process.env.NEXT_PUBLIC_OKTA_NONCE,
    scope: OKTA_SCOPES.join(' '),
    response_mode: OKTA_RESPONSE_MODE,
    response_type: OKTA_RESPONSE_TYPE.join(' '),
    redirect_uri: process.env.NEXT_PUBLIC_OKTA_LOGIN_REDIRECT_URL,
  }

  const q = qs.stringify(params);

  return `${process.env.NEXT_PUBLIC_OKTA_ISSUER}/v1/authorize?${q}`;
}

export {
  getAuthClient,
  getOktaSessionToken,
  getIdpLoginHref,
  convertErrorCode,
  getExternalIDPLoginHrefByType,
};
