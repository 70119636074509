import { request } from '@/libs/index';

function resetPasswordRequest(
  { email, password, token } = { email: '', password: '', token: '' }
) {
  return request({
    method: 'POST',
    url: '/reset',
    data: {
      username: email,
      password: password,
      password_reset_token: token,
    },
  }).catch((e) => Promise.reject(e));
}

export { resetPasswordRequest as default };
