import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import OktaSignIn from '@okta/okta-signin-widget';

export const OktaSignInWidget = ({ onError }) => {
  const widgetRef = useRef(null);
  const ORIGINAL_AUTH_METHOD = 'auth_method'

  useEffect(() => {
    const widget = new OktaSignIn({
      baseUrl: process.env.NEXT_PUBLIC_OKTA_DOMAIN,
      clientId: process.env.NEXT_PUBLIC_OKTA_CLIENT_ID,
      redirectUri: process.env.NEXT_PUBLIC_OKTA_LOGIN_REDIRECT_URL,
      authParams: {
        issuer: process.env.NEXT_PUBLIC_OKTA_ISSUER,
        pkce: false,
        responseType: ['token', 'id_token'],
        display: 'page',
        scopes: ['openid', 'profile', 'email'],
      },
      helpLinks: {
        forgotPassword: '/forgot-password',
      },
      i18n: {
        'en': {
          'primaryauth.title': ' ', // Change the sign in text
          'primaryauth.submit': 'Log In', // Change the sign in button text
          'primaryauth.username.placeholder': 'Email', // Change user name text 
          'primaryauth.password.placeholder': 'Password', // Change password text 
          'needhelp': ' ', // We do not need the needHelp link, hide it
          'help': ' ', // We do not need the need helpLink, hide it
          'error.username.required': 'Email must be a valid email.', // Update the email validation text
          'error.password.required': 'Password is required.' // Update the password validation text
        }
      },
      features: {
        rememberMe: false, // Remove the remember me setting
        showPasswordToggleOnSignInPage: true, // Enable the show password icon in the password text box
        idpDiscovery: false, // Enable IDP discovery
      },
      idpDisplay: 'SECONDARY', // To display IDP options under the primary Okta sign-in form
      customButtons: [
        {
          title: 'Login with SSO',
          className: 'btn-custom-login-sso',
          click: function() {
            sessionStorage.setItem(ORIGINAL_AUTH_METHOD, 'external_sso');
            window.location.href = '/sso-signin';
          }
        },
      ],
      useInteractionCodeFlow: false,
      useClassicEngine: true
    });

    widget
      .showSignInAndRedirect({
        el: widgetRef.current,
      })
      .catch(onError);

    // Forget password link was hidden in the needHelp, which means only if we click the needHelp link, the forgot password link would be seen
    // In order to realize that only forgot password will be seen, we have to click the needHelp link
    widget.on('afterRender', function () {
      if ((document.querySelectorAll("[data-se='needhelp']") || []).length > 0)
    document.querySelectorAll("[data-se='needhelp']")[0].click()
    });

    // There are not existed placeholder in the username or password
    // Add them via dom operation
    widget.on('afterRender', function () {
      if (document.getElementById('okta-signin-username')) {
        document.getElementById('okta-signin-username').placeholder = "Enter email"
      }
      if (document.getElementById('okta-signin-password')) {
        document.getElementById('okta-signin-password').placeholder = "Enter password"
      }
    });

    // Auto click the send me the code button when the MFA page loaded
    widget.on('afterRender', function () {
      if (document.querySelector("[data-type='save']")?.value === 'Send me the code')
          document.querySelector("[data-type='save']").click()
    });

    // Register the event listener for the login btn
    widget.on('afterRender', function () {
      const loginButton = document.querySelector('#okta-signin-submit');
      if (loginButton) {
        loginButton.addEventListener('click', () => {
          // Store the auth method to session storage
          sessionStorage.setItem(ORIGINAL_AUTH_METHOD, 'username_password');
        });
      }
    });

    // Hack the error info when error occurred
    widget.on('afterError', (_, err ) => {
      let errInfo = 'Authentication failed.'

      if (err?.xhr?.responseJSON?.errorCode === 'E0000004') {
        errInfo = 'Invalid username and password combination.'
      }
        
      if (err?.xhr?.responseJSON?.errorCode === 'E0000068'){
        errInfo = 'Your code doesn\'t match our records, Please try again.'
      }

      if (document.getElementsByClassName('okta-form-infobox-error infobox infobox-error')[0].childNodes[1]) {
        document.getElementsByClassName('okta-form-infobox-error infobox infobox-error')[0].childNodes[1].textContent = errInfo
      }
    })

    return () => {
      try {
        // cleanup the widget when we're done
        widget.remove();
      } catch (e) {
        console.log('could not remove okta widget. already removed.');
      }
    };
  }, [onError]);

  return <div ref={widgetRef} />;
};

OktaSignInWidget.propTypes = {
  onError: PropTypes.func,
};


export default OktaSignInWidget;