import { request } from '@/libs/index';

function resendEmailRequest(data = { email: '' }) {
  return request({
    method: 'POST',
    url: '/resend',
    data: {
      username: data.email,
    },
  }).catch((e) => Promise.reject(e));
}

export { resendEmailRequest as default };
