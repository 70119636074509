import PropTypes from 'prop-types';

function ShowAlert({ children }) {
  return (
    <div className="flex" data-testid="alert-message">
      <div className="flex w-full p-8 rounded-md bg-primary-100 border border-primary-700">
        <span className="material-icons inline-block text-medium text-neutral-1000">
          error_outline
        </span>
        <span className="inline-block ml-8 text-regular leading-paragraph text-neutral-1000 align-middle capitalize-first">
          {children}
        </span>
      </div>
    </div>
  );
}

ShowAlert.propTypes = {
  children: PropTypes.element,
};

export default ShowAlert;
