import { request } from '@/libs/index';

function confirmEmailRequest({ token } = { token: '' }) {
  return request({
    method: 'POST',
    url: '/confirm',
    data: {
      token,
    },
  }).catch((e) => Promise.reject(e));
}

export { confirmEmailRequest as default };
