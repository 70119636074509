import ls from 'local-storage';

if (process.browser) {
  ls.backend(sessionStorage);
}

function getFromStorage(key) {
  try {
    return ls.get(key);
  } catch (ex) {
    return '';
  }
}

function removeFromStorage(key) {
  try {
    ls.remove(key);
  } catch (ex) {
    return;
  }
}

function setToStorage(key, value) {
  try {
    ls.set(key, value);
  } catch (ex) {
    return;
  }
}

export { getFromStorage, removeFromStorage, setToStorage };
